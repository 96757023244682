import {useEffect, useRef} from 'react';
import {Mesh, RepeatWrapping} from 'three';
import {useFrame} from '@react-three/fiber';
import helper from '../../helper';
import {useTexture} from '@react-three/drei';
import {ResponsiveImage} from '../../model';

interface ImageCubeProps {
    image: ResponsiveImage
}

export function ImageCube({image}: ImageCubeProps) {
    const ref = useRef<Mesh>(null);
    useEffect(() => {
        ref.current?.rotation.set(0, 0 , helper.deg2rad(180));
    }, [image]);
    useFrame((state, delta, xrFrame) => {
        const {x, y, z} = ref.current?.rotation!;
        const degX = helper.rad2deg(x);
        const degY = helper.rad2deg(y);
        const degZ = helper.rad2deg(z);
        const rX = degX + delta * 2;
        const rY = degY + delta * 2;
        const rZ = degZ + delta * 2;
        ref.current?.rotation.set(helper.deg2rad(rX), helper.deg2rad(rY), helper.deg2rad(rZ));
    })
    const texture = useTexture(helper.getSrcFromSrcSet(image.large)) // Change with the real path to your image file
    texture.flipY = false;
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.repeat.set(1, 1);
    texture.offset.set(0, 0);
    const w = 30;
    return (
        <mesh ref={ref} position={[0, -6, -5]}>
            <boxGeometry attach="geometry" args={[w, w * 1.5, w]}/>
            <meshBasicMaterial side={1} attach="material" map={texture}/>
        </mesh>
    )
}
