import {useMemo, useRef} from 'react';
import {Group} from 'three';
import {Euler, useFrame} from '@react-three/fiber';
import helper from '../../helper';
import MirrorCube from '../ShowcaseInterior/Mirror/MirrorCube';
import Logo from '../Logo/Logo';

export function LoadingEntity() {
    const ref = useRef<Group>(null);
    const speed = 10;
    useFrame((state, delta, xrFrame) => {
        const {x, y, z} = ref.current?.rotation!;
        const degX = helper.rad2deg(x);
        const degY = helper.rad2deg(y);
        const degZ = helper.rad2deg(z);
        const rX = degX + delta * speed;
        const rY = degY + delta * speed;
        const rZ = degZ + delta * speed;
        ref.current?.rotation.set(helper.deg2rad(rX), helper.deg2rad(rY), helper.deg2rad(rZ));
    })
    const startRotation = useMemo(() => [Math.random(), Math.random(), Math.random()], [ref])
    const s = 0.5;
    return <group ref={ref} rotation={startRotation as Euler} position={[0, 0, -2.5]} scale={[s, s, s]}>
        <Logo/>
    </group>
}
