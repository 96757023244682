import {FC, useCallback, useEffect, useRef, useState} from 'react';
import './AboutPage.css';
import {fetchJson} from '../../api/api';
import {About, PageSummary, ResponsiveImage} from '../../model';
import helper from '../../helper';
import {Outlet, useLoaderData, useNavigate, useOutletContext} from 'react-router-dom';
import useScroll from '../../hooks/useScroll';
import {LoadingScreen} from '../Loading/LoadingScreen';
import {useIsSubpageShown} from '../../hooks/useIsSubpageShown';

export async function loader() {
    const about = await fetchJson('/about') as About;
    await helper.loadSizedImage(about.cover, 'large');
    return {about};
}


const AboutPage: FC = () => {
    const navigate = useNavigate();
    const {about} = useLoaderData() as { about: About };
    const [setBackgroundImage] = useOutletContext() as [(image: ResponsiveImage) => void];
    const [showTopLink, setShowTopLink] = useState(false);
    const childrenEl = useRef<HTMLDivElement>(null);
    const el = useRef<HTMLDivElement>(null);
    const subpage = useRef<HTMLDivElement>(null);
    const childrenBottom = childrenEl.current && childrenEl.current.offsetTop + childrenEl.current.clientHeight;
    const scroll = useScroll(el);
    const toTop = useCallback(() => {
        if (el.current) {
            el.current.scrollTo({top: 0, behavior: 'smooth'});
            console.log("SCROLLING CALLED")
        }
    }, [el]);
    const isSubpageShown = useIsSubpageShown('/about');
    useEffect(() => {
        setTimeout(() => {
            if (isSubpageShown && subpage.current) {
                subpage.current.scrollIntoView({behavior: 'smooth'});
            }
        })
    }, [isSubpageShown])
    useEffect(() => {
        about && setBackgroundImage(about.cover);
    }, [about, setBackgroundImage]);
    useEffect(() => {
        setShowTopLink(!!childrenBottom && scroll.scrollTop > childrenBottom);
    }, [scroll, childrenBottom]);

    return <div className={'overlay-page about'} ref={el}>
        <button className={'close'} onClick={() => navigate('..', {relative: 'path'})}>X</button>
        {showTopLink && <button className={'up'} title="back to top" onClick={toTop}>↑</button>}
        {!about && <LoadingScreen/>}
        {about && <div className={'content'}>
            <div className="text">
                <hr></hr>
                <h1>About EAT IT</h1>
                <div dangerouslySetInnerHTML={{__html: about!.text}}></div>
                <div className={'children'} ref={childrenEl}>
                    <hr></hr>
                    <ul>
                        {about!.children.map((child, i) => <AboutLink key={i} {...child}/>)}
                    </ul>
                </div>
                <hr></hr>
            </div>
            <div ref={subpage}>
                <Outlet/>
            </div>
        </div>}
    </div>
};

const AboutLink: FC<PageSummary> = ({date, title, slug }) => {
    const navigate = useNavigate();
    return <li className="about">
        <button className={'shimmer'} onClick={() => {
            navigate(`/about/${slug}`);
        }}>{title}</button>
    </li>
}

export default AboutPage;
