import './ArtistDetails.css'
import {Artist} from '../../model';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {fetchJson} from '../../api/api';
import helper from '../../helper';
import {Gallery} from '../Gallery/Gallery';
import {LoadingScreen} from '../Loading/LoadingScreen';

export async function loader({params}: any) {
    const {artistId} = params;
    const artist = await fetchJson(`/artists/${artistId}`) as Artist;
    await Promise.all(artist.images.map(helper.loadImageResponsive))
    return {artist}
}


function ArtistDetails() {
    const navigate = useNavigate();
    const {artist} = useLoaderData() as { artist: Artist }

    return <div className={'overlay-page artist-details'}>
        {!artist && <LoadingScreen/>}
        <button className={'close'} onClick={() => navigate('..', {relative: 'path'})}>X</button>
        {artist && <div className="artist content">
            <div className="text">
                <h1>{artist!.name}</h1>
                <div dangerouslySetInnerHTML={{__html: artist!.text}}></div>
                <ul>
                    <li> {artist!.instagram &&
                        <a referrerPolicy={'no-referrer'} href={`https://instagram.com/${artist!.instagram.replace("@","")}`}
                           rel={'no-referrer'} target={'_blank'}>{artist!.instagram}</a>}</li>
                    <li> {artist!.link && <a referrerPolicy={'no-referrer'} href={`${artist!.link}`}
                                             rel={'no-referrer'} target={'_blank'}>{artist!.link}</a>}</li>
                </ul>
            </div>
            <Gallery images={artist.images}></Gallery>
        </div>}
    </div>
}





export default ArtistDetails;
