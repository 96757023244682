import {useThree} from '@react-three/fiber';
import {animated, useSpring} from '@react-spring/three';
import {PerspectiveCamera} from '@react-three/drei';
import { useDrag } from "@use-gesture/react";
import helper from '../helper';

const AnimatedCam = animated(PerspectiveCamera);

export default function ShowcaseCamera() {
    const { camera } = useThree();
    const [spring, api] = useSpring(() => ({
        rotation: [0,0,0]
    }))

    useDrag(
        ({ delta: [x, y], event }) => {
            if(helper.isMobile()) return;
            const radY = camera.rotation.y;
            const degY = helper.rad2deg(radY);
            const rY = degY + x;
            const radX = camera.rotation.x;
            const degX = helper.rad2deg(radX);
            const rX = degX + y;

            api.start({
                rotation: [helper.deg2rad(rX), helper.deg2rad(rY), 0]
            });
        },
        {
            target: window
        }
    );

    return <AnimatedCam makeDefault {...spring as any as {rotation:[number, number, number]}} position={[0, 0.5, 0]} />;
}
