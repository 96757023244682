import {doesHaveVideo, HasVideo, ResponsiveImage} from '../../model';
import {ImageWithDateComponent} from '../ImageWithDateComponent';
import React, {FC, useCallback, useState} from 'react';
import './Gallery.css'
import {Video} from '../Video/Video';

export const Gallery: FC<{ images: ResponsiveImage[] }> = ({images}) => {
    const [video, setVideo] = useState<HasVideo | null>(null);
    const handleClick = useCallback((image: ResponsiveImage) => {
        if (doesHaveVideo(image)) {
            setVideo({...image})
        }
    }, [setVideo])
    return <div className="gallery">
        {images.map((image, i) => <ImageWithDateComponent image={image} onClick={() => handleClick(image)} key={i}/>)}
        {video && <div className="video">
            <button className={'close'} onClick={() => setVideo(null)}>X</button>
            <Video {...video}></Video>
        </div>}
    </div>
}
