import {FC, useEffect, useRef} from 'react';
import {EatitEvent, ResponsiveImage} from '../../model';
import helper from '../../helper';
import {useLoaderData, useNavigate, useOutletContext} from 'react-router-dom';
import {fetchJson} from '../../api/api';
import './EventPage.css';
import {Gallery} from '../Gallery/Gallery';

export async function loader({params}: any) {
    const {eventId} = params;
    const event = await fetchJson(`/events/${eventId}`) as EatitEvent;
    return {event}
}

const EventPage: FC = () => {
    const {event} = useLoaderData() as { event: EatitEvent };
    const [setBackgroundImage] = useOutletContext() as [(image: ResponsiveImage) => void];
    useEffect(() => {
        event && event.images[0] && setBackgroundImage(event.images[0]);
    }, [event, setBackgroundImage]);


    return <section className="event">
        {event && <>
            <div className="text">
                <h1>{event.title}</h1>
                {helper.parseDate(event.date, {}) + " " + (event.eventLocation || "")}
                <ul className="artists">
                    {event.artists.map((artist, i) => <EventArtist key={i} {...artist} />)}
                </ul>
                <div dangerouslySetInnerHTML={{__html: event.text}}></div>
            </div>
            <Gallery images={event.images}></Gallery>
        </>}
    </section>
}
const EventArtist: FC<{ name: string, id: string }> = ({name, id}) => {
    const navigate = useNavigate();
    return <li className="artist">
        <button className="shimmer" onClick={() => navigate(`/artists/${id}`)}>{name}</button>
    </li>
}

export default EventPage;
