/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei";
import {Mesh} from 'three';
import {ObjectMap} from '@react-three/fiber';
export interface LogoGlbProps {}
const gltf = require('./logo.gltf');

export function Model(props: LogoGlbProps) {
    const { nodes, materials } = useGLTF(gltf) as ObjectMap;
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.e_remesh as Mesh).geometry}
                material={materials.Mat}
                position={[-0.235, 0, 0]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.a_remesh as Mesh).geometry}
                material={materials.Mat}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.t_remesh as Mesh).geometry}
                material={materials.Mat}
                position={[0.12, 0, 0]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.i_remesh as Mesh).geometry}
                material={materials.Mat}
                position={[-0.234, -0.352, 0]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.t_remesh_1 as Mesh).geometry}
                material={materials.Mat}
                position={[0.093, -0.385, 0]}
            />
        </group>
    );
}

useGLTF.preload(gltf);
