
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createHashRouter, RouterProvider} from 'react-router-dom';
import App, {loader as artistsLoader} from './App';
import reportWebVitals from './reportWebVitals';
import {ErrorPage} from './components/ErrorPage/ErrorPage';
import ArtistDetails, {loader as artistLoader} from './components/ArtistDetails/ArtistDetails';
import AboutPage, {loader as aboutLoader} from './components/AboutPage/AboutPage';
import EventPage, {loader as eventLoader} from './components/EventPage/EventPage';
import EventsPage, {loader as eventsLoader}  from './components/EventsPage/EventsPage';
import DefaultPage, {loader as defaultLoader} from './components/DefaultPage/DefaultPage';

const router = createHashRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        loader: artistsLoader,
        children: [
            {
                path: "/artists",
                element: null,
                children: [
                    {
                        path: "/artists/:artistId",
                        element: <ArtistDetails />,
                        loader: artistLoader,
                    },
                ]
            },
            {
                path: "/about",
                element: <AboutPage />,
                loader: aboutLoader,
                children:[
                    {
                        path: "/about/:slug",
                        element: <DefaultPage />,
                        loader: defaultLoader,
                    }]
            },
            {
                path: "/events",
                element: <EventsPage />,
                loader: eventsLoader,
                children:[
                    {
                        path: "/events/:eventId",
                        element: <EventPage />,
                        loader: eventLoader,
                    }
                ]
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
reportWebVitals();
