import {FC, ReactEventHandler} from 'react';
import {ResponsiveImage} from '../model';

const ResponsiveImageComponent: FC<ResponsiveImage & { onImageLoad?: ReactEventHandler<HTMLImageElement> }> =
    ({small, medium, large, src, srcSet, alt, onImageLoad}) => {
        return <>
            <picture>
                <source media="(min-width: 801px)" srcSet={large}/>
                <source media="(min-width: 601px)" srcSet={medium}/>
                <source media="(max-width: 600px)" srcSet={small}/>
                <img
                    onLoad={onImageLoad}
                    src={src}
                    srcSet={srcSet}
                    sizes="(max-width: 600px) 600px, (min-width: 601px) 800px, (min-width: 801px) 1024px"
                    alt={alt}/>
            </picture>
        </>
    }

export default ResponsiveImageComponent;

