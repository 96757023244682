import {ResponsiveImage} from './model';

function getSrcFromSrcSet(srcSet: string) {
    const [src] = srcSet.split(' ');
    return src;
}

function getResponsiveImageSource(image: ResponsiveImage) {
    const width = window.innerWidth;
    if (width < 601) {
        return getSrcFromSrcSet(image.small);
    }
    if (width < 801) {
        return getSrcFromSrcSet(image.medium)
    }
    return getSrcFromSrcSet(image.large);

}

function loadImage(src: string) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function () {
            resolve(img)
        }
        img.onerror = img.onabort = function () {
            reject(src)
        }
        img.src = src
    })
}

function debounce<F extends Function>(func: F, wait: number, immediate: boolean = false): F {
    let timeout: number | undefined;

    return function executedFunction(this: any) {
        const context: any = this;
        const args = arguments;

        const later = () => {
            timeout = undefined;
            if (!immediate) {
                func.apply(context, [args]);
            }
        };

        const callNow: boolean = immediate && !timeout;

        clearTimeout(timeout);

        timeout = window.setTimeout(later, wait);

        console.log(callNow, timeout);

        if (callNow) {
            return func.apply(context, [args]);
        }
    } as any as F;
}

function throttle(fn: (args: any[]) => any, wait: number) {
    let shouldWait = false;

    return function() {
        if (!shouldWait) {
            fn([arguments]);
            shouldWait = true;
            setTimeout(() => shouldWait = false, wait);
        }
    };
}

const helper = {
    rad2deg: (radians: number) => (radians * 180) / Math.PI,
    deg2rad: (degrees: number) => (degrees * Math.PI) / 180,
    getSrcFromSrcSet,
    loadSizedImage: (image: ResponsiveImage, size: 'small' | 'medium' | 'large') => {
        return loadImage(getSrcFromSrcSet(image[size]));
    },
    loadImageResponsive: (image: ResponsiveImage) => {
        return loadImage(getResponsiveImageSource(image));
    },
    isMobile() {
        return window.innerWidth < 601 || window.innerHeight < 601;
    },
    parseDate(date: string, options?: Intl.DateTimeFormatOptions) {
        return new Date(date).toLocaleDateString('en', options);
    },
    imageOrientation(img: HTMLImageElement) {
        let orientation = '';

        if (img.naturalWidth > img.naturalHeight) {
            orientation = 'landscape';
        } else if (img.naturalWidth < img.naturalHeight) {
            orientation = 'portrait';
        } else if (img.naturalWidth === img.naturalHeight){
            orientation = 'even';
        }
        return orientation;
    },
    debounce,
    throttle
}

export default helper;
