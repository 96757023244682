import helper from '../../helper';
import Logo from '../Logo/Logo';
import MirrorBox from './Mirror/MirrorBox';
import {ImageCube} from './ImageCube';
import {Fragment} from 'react';
import {ResponsiveImage} from '../../model';

interface ShowcaseFurnitureProps {
    picture: ResponsiveImage;
    logoRotation: [number, number, number];
}

export function ShowcaseInterior({picture, logoRotation}: ShowcaseFurnitureProps){
    return <Fragment>
        <group position={[0, 0.5, -5]} rotation={[0, 0, 0]} scale={0.3}>
            <group rotation={logoRotation}>
                <Logo position={[0, -2, 0]} scale={1.4}></Logo>
            </group>
            {! helper.isMobile() && <MirrorBox planeGeometryArgs={[12, 12]}/>}
        </group>
        <ImageCube image={picture}/>
    </Fragment>
}
