import './LoadingScreen.css';
export function LoadingScreen() {
    return <div className="loading-screen">
        <div className="loading-indicator">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}
