import {FC, useCallback, useEffect, useRef, useState} from 'react';
import './EventsPage.css';
import {fetchJson} from '../../api/api';
import {Events, PageSummary, ResponsiveImage} from '../../model';
import helper from '../../helper';
import {Outlet, useLoaderData, useNavigate, useOutletContext} from 'react-router-dom';
import useScroll from '../../hooks/useScroll';
import {LoadingScreen} from '../Loading/LoadingScreen';
import {useIsSubpageShown} from '../../hooks/useIsSubpageShown';
import {is} from '@react-three/fiber/dist/declarations/src/core/utils';

export async function loader() {
    const events = await fetchJson('/events') as Events;
    await helper.loadSizedImage(events.cover, 'large');
    return {events};
}


const EventsPage: FC = () => {
    const navigate = useNavigate();
    const el = useRef<HTMLDivElement>(null);
    const subPageEl = useRef<HTMLDivElement>(null);
    const eventHistory = useRef<HTMLDivElement>(null);
    const eventHistoryBottom = eventHistory.current && eventHistory.current.offsetTop + eventHistory.current.clientHeight
    const toEventhistoryTop = useCallback(() => {
        if (el.current) {
            el.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, [el]);
    const scroll = useScroll(el);
    const [showEventhistoryTopLink, setShowEventhistoryTopLink] = useState(false);
    const {events} = useLoaderData() as { events: Events };
    const [setBackgroundImage] = useOutletContext() as [(image: ResponsiveImage) => void];
    const isSubPageShown = useIsSubpageShown('/events');
    useEffect(() => {
        setTimeout(() => {
            if (isSubPageShown && subPageEl.current) {
                subPageEl.current.scrollIntoView({behavior: 'smooth'});
            }
        })
    }, [isSubPageShown])
    useEffect(() => {
        setShowEventhistoryTopLink(!!eventHistoryBottom && scroll.scrollTop > eventHistoryBottom);
    }, [scroll, eventHistoryBottom]);
    useEffect(() => {
        events && setBackgroundImage(events.cover);
    }, [events, setBackgroundImage]);

    return <div className={'overlay-page events'} ref={el}>
        <button className={'close'} title="home" onClick={() => navigate('/', {relative: 'path'})}>X</button>
        {showEventhistoryTopLink && <button className={'up'} title="back to top" onClick={toEventhistoryTop}>↑</button>}
        {!events && <LoadingScreen/>}
        {events && <div className={'content'}>
            <div className="text">
                <div className="event-history" ref={eventHistory}>
                    <hr></hr>
                    <ul>
                        {events!.events.map((event, i) => <EventLink key={i} {...event}/>)}
                    </ul>
                </div>
                <hr></hr>
            </div>
            <div ref={subPageEl}>
                <Outlet context={[setBackgroundImage]}/>
            </div>
        </div>}
    </div>
};

const EventLink: FC<PageSummary> = ({title, slug}) => {
    const navigate = useNavigate();
    return <li className="">
        <button className="shimmer" onClick={() => navigate(`/events/${slug}`)}>{title}</button>
    </li>
}

export default EventsPage;
