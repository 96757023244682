import {useLocation, useMatch} from 'react-router-dom';
import usePrevious from './usePrevious';
import {useMemo} from 'react';


export const useIsSubpageShown = (path: string) => {
    const location = useLocation();
    const subpage = useMatch(`${path}/:slug`);
    const previous = usePrevious(location);
    return useMemo(() => {
        return !!subpage && previous != location;
    },[previous, location, subpage])

};
