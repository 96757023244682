import {useThree} from '@react-three/fiber';
import {Color} from 'three';

export function Sky() {
    const {scene} = useThree();
    // Set the scene background property to the resulting texture.
    scene.background = new Color(0xff1dce);
    //scene.background = texture;
    return null;
}
