import ResponsiveImageComponent from './ResponsiveImageComponent';
import {ResponsiveImage} from '../model';
import {useState} from 'react';
import helper from '../helper';

export const ImageWithDateComponent = ({image, onClick}: {image: ResponsiveImage, onClick: ()=>void}) => {
    const originalDate = image.date && new Date(parseInt(image.date)).toLocaleString('en');
    const [orientation, setOrientation] = useState('');
    const onImageLoad = (e: any) => {
        setOrientation(helper.imageOrientation(e.target));
    }
    return <div className={`image ${orientation} ${image.video?"has-video":""}`} onClick={onClick}>
        <ResponsiveImageComponent {...image} onImageLoad={onImageLoad}/>
        <div className={'original-date'}>{originalDate}</div>
    </div>
}
