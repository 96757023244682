import {FC} from 'react';
import "./NextEvent.css";

export const NextEvent: FC<{date: Date, onClick: () => void}> =
    ({date, onClick}) => {
        return <div className={"next-event"} onClick={onClick}>
            <section>
                <h1>Next Date</h1>
                <p>{date.toLocaleDateString("en-US")}</p>
                <h2>Tickets Via</h2>
                <a href={"https://instagram.com/_EAT___IT_"} target={"_blank"} rel={"external"}>@_EAT___IT_</a>
            </section>
        </div>
    }
