import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import './ArtistsMenu.css'
import {Artist, ResponsiveImage} from '../../model';
import {useMatch, useNavigate} from 'react-router-dom';
import helper from '../../helper';
import useDebounced from '../../hooks/useDebounce';
import useScrollArtistMenu from './useScroll';


function scrollToCenter(id: string) {
    const item = document.getElementById(`menu-item-${id}`);
    item && item.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
}


export type ArtistsMenuProps = {
    artists: Artist[],
    setBackgroundImage: (image: ResponsiveImage) => void,
    setLogoRotation: (rotation: [number, number, number]) => void,
}

export function ArtistsMenu({artists, setBackgroundImage, setLogoRotation}: ArtistsMenuProps) {
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const [enabled, setEnabled] = useState(false);
    let artistsPageMatch = useMatch('/artists/');
    let landingPageMatch = useMatch('/');
    let artistPageMatch = useMatch('/artists/:artistId');
    const el = useRef<HTMLDivElement>(null);
    const scrollState = useScrollArtistMenu(el);
    const getArtist = useCallback((id: string) => artists.find(p => p.id === id), [artists]);
    const scrollStateDebounced = useDebounced(scrollState, 300);
    const select = useCallback( (id: string) => {
        if (id === selected) {
            return;
        }
        setSelected(id);
        setBackgroundImage(getArtist(id)!.cover);
        scrollToCenter(id);
    }, [selected, setSelected, getArtist, setBackgroundImage])
    const items = useMemo(() =>
        artists.map(artist => <MenuItem key={artist.id} artist={artist}
                                        selected={selected === artist.id}
                                        select={select}/>), [artists, selected, select]);
    useEffect(() => {
        const {scrollPercentage} = scrollState;
        setLogoRotation([helper.deg2rad(scrollPercentage * 3.6), 0, 0])
    }, [setLogoRotation, scrollState]);

    useEffect(() => {
        const shouldEnable = artistPageMatch || artistsPageMatch || landingPageMatch;
        setEnabled(!!shouldEnable);
    }, [setEnabled, artistPageMatch, artistsPageMatch, landingPageMatch]);

    useEffect(() => {
        if (artistPageMatch) {
            setEnabled(true);
            select(artistPageMatch.params.artistId!);
        }
    }, [artistPageMatch, setEnabled, select]);

    useEffect(() => {
        if (artistsPageMatch || landingPageMatch) {
            setEnabled(true);
            if (scrollStateDebounced) {
                select(scrollStateDebounced.centered || artists[0].id);
            }
        }
    }, [setEnabled, select, artists, artistsPageMatch, landingPageMatch, scrollStateDebounced]);

    return <div className={'menu ' + (enabled ? 'enabled' : 'disabled')} id={'menu'} ref={el}>
        {items}
    </div>
}

type MenuItemProps = {
    artist: Artist,
    selected: boolean,
    select: (id: string) => void,
}

export const MenuItem = ({artist, selected, select}: MenuItemProps) => {
    const navigate = useNavigate();
    const onClick = selected ? () => navigate(`/artists/${artist.id}`) : () => select(artist.id);
    return <div data-performer={artist.id} id={`menu-item-${artist.id}`}
                className={`menu-item ${selected ? 'selected' : ''}`}
                onClick={onClick}>
        <label>{artist.name}</label>
    </div>;
}
