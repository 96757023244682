import './App.css';
import {useNavigate, Outlet, useLoaderData, useNavigation, Await, defer, useMatch} from 'react-router-dom';

import {Canvas} from '@react-three/fiber';
import {Sky} from './components/Sky';

import {ShowcaseInterior} from './components/ShowcaseInterior/ShowcaseInterior';
import helper from './helper';
import ShowcaseCamera from './components/ShowcaseCamera';
import {Suspense, useState} from 'react';
import RotatingLogo from './components/Logo/RotatingLogo';
import {ArtistsMenu} from './components/ArtistsMenu/ArtistsMenu';
import {Artist, ResponsiveImage} from './model';
import {fetchJson} from './api/api';
import {LoadingScreen} from './components/Loading/LoadingScreen';
import {LoadingEntity} from './components/Loading/LoadingEntity';
import {NextEvent} from './components/NextEvent/NextEvent';

export async function loader() {
    const res1 = fetchJson('/artists').then((artists: Artist[]) => {
        return Promise.all(artists.map((p) => helper.loadSizedImage(p.cover, 'large'))).then(() => artists);
    });
    const res2 = fetchJson('/home').then((data: { nextEvent: Date }) => {
        return Promise.resolve(data);
    });
    return defer({artists: res1, data: res2});
}

function App() {
    const [backgroundImage, setBackgroundImage] = useState<ResponsiveImage | undefined>(undefined);
    const [logoRotation, setLogoRotation] = useState<[number, number, number]>([0, 0, 0]);
    const [showNextEvent, setShowNextEvent] = useState<boolean>(true);
    const {state} = useNavigation();
    const loading = state === 'loading';
    const navigate = useNavigate();
    const loaderData = useLoaderData() as { artists: Artist[], data: { nextEvent: string } };
    let aboutPageMatch = useMatch('/about/*');
    let artistsPageMatch = useMatch('/artists');
    let homePageMatch = useMatch('/');

    return (
        <div className="App">
            {loading && <LoadingScreen/>}
            <div id="canvas-container">
                <Canvas>
                    <ambientLight color="#ff1dce" intensity={0.3}/>
                    <directionalLight color="#ff0000" intensity={0.9} position={[-550, -100, 0]}/>
                    <directionalLight color="#00ff00" intensity={0.9} position={[550, -100, 0]}/>
                    <directionalLight color="#f4bbff" intensity={0.5} position={[0, -100, 550]}/>
                    <Sky></Sky>
                    {loading && <LoadingEntity/>}
                    <Suspense fallback={<LoadingEntity/>}>
                        <Await resolve={loaderData.artists}>
                            {backgroundImage &&
                                <ShowcaseInterior picture={backgroundImage} logoRotation={logoRotation}/>}
                        </Await>
                    </Suspense>

                    <ShowcaseCamera/>
                </Canvas>
            </div>
            <div id="logo-container" onClick={() => navigate('/events')}>
                <Canvas style={{background: 'transparent'}}>
                    <ambientLight color="#ff1dce" intensity={0.3}/>
                    <directionalLight color="#ff0000" intensity={0.9} position={[-550, -100, 0]}/>
                    <directionalLight color="#00ff00" intensity={0.9} position={[550, -100, 0]}/>
                    <directionalLight color="#f4bbff" intensity={0.5} position={[0, -100, 550]}/>
                    <Suspense fallback={null}>
                        <RotatingLogo position={[0, -1.5, 0]} scale={.95}></RotatingLogo>
                    </Suspense>
                </Canvas>
            </div>
            {!aboutPageMatch && <div className="about-button">
                <button onClick={() => navigate('/about')}>(I)</button>
            </div>}
            <Suspense fallback={<LoadingScreen/>}>
                <Await resolve={loaderData.artists}>
                    {(artists) => (
                        <>
                            <ArtistsMenu artists={artists} setBackgroundImage={setBackgroundImage}
                                         setLogoRotation={setLogoRotation}/>
                            <Outlet context={[setBackgroundImage, setLogoRotation]}/>
                        </>
                    )}
                </Await>
                <Await resolve={loaderData.data}>
                    {(data) => (
                        <>
                            {(homePageMatch || artistsPageMatch) && showNextEvent && Date.parse(data.nextEvent) > Date.now() &&
                                <NextEvent date={new Date(Date.parse(data.nextEvent))}
                                           onClick={() => setShowNextEvent(false)}/>}
                        </>
                    )}
                </Await>
            </Suspense>
        </div>
    );
}

export default App;
