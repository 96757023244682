export interface ResponsiveImage  extends MightHaveVideo{
    src: string,
    alt: string,
    small: string,
    medium: string,
    large: string,
    srcSet: string,
    date: string,
}

export interface MightHaveVideo{
    video?: string,
    videoMime?: string,
}

export interface HasVideo{
    video: string,
    videoMime: string,
}

export function doesHaveVideo(mightHave: MightHaveVideo): mightHave is HasVideo {
    return !!mightHave.video && !!mightHave.videoMime;
}

export interface Artist {
    name: string,
    id: string,
    link: string,
    instagram: string,
    text: string,
    cover: ResponsiveImage,
    images: ResponsiveImage[]
}

export interface PageSummary {
    slug: string,
    date: string,
    title: string,
}

export interface EatitEvent extends PageSummary{
    text: string,
    images: ResponsiveImage[]
    eventLocation: string
    artists: {name: string, id: string}[]
}

export interface DefaultPageContent extends PageSummary{
    text: string,
}


export interface About {
    slug: string,
    text: string,
    cover: ResponsiveImage,
    children: PageSummary[]
}

export interface Events {
    slug: string,
    text: string,
    cover: ResponsiveImage,
    events: PageSummary[]
}

export interface ScrollState {
    scrollTop: number,
    scrollHeight: number,
    offsetHeight: number,
    scrollPercentage: number
}
