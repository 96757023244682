import React, {FC, useRef} from 'react';
import {useFrame, Vector3} from '@react-three/fiber';
import {Group} from 'three';
import helper from '../../helper';
import {Model} from './Model';

interface RotatingLogoProps {scale?: Vector3, position?: Vector3}

const RotatingLogo: FC<RotatingLogoProps> = (props: RotatingLogoProps) => {
    const ref = useRef<Group>(null);
    useFrame((state, delta, xrFrame) => {
        if(!ref.current) return;
        const { y} = ref.current!.rotation!;
        const degY = helper.rad2deg(y);
        const rY = degY + delta * 50;
        ref.current?.rotation.set(0, helper.deg2rad(rY),0);
    }, )
    const {position} = props;
    return <group position={position || 0} scale={props.scale || 1} ref={ref}>
        <Model/>
    </group>
};

export default RotatingLogo;
