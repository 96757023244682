import {RefObject, useEffect, useState} from 'react';
import {ScrollState} from '../../model';

export interface ArtistMenuScrollState extends ScrollState {
    centered: string,
}

function isCentered(item: HTMLElement) {
    const center = Math.ceil(window.innerHeight / 2);
    const height = Math.ceil(item.offsetHeight);
    const top = Math.ceil(item.getBoundingClientRect().top);
    const centered = top >= center - height && top <= center;
    const centerOffset = Math.abs(top + height / 2 - center) / center;
    let rotate = centered? 0: (top < center? -1: 1) * Math.min(1, centerOffset)  * 75;
    let opacity = centered? .9: .9 - Math.min(.75, centerOffset) * .6;
    let color = centered? 'white': 'deeppink';
    let scale = centered? 1: 1 - Math.min(0.9, centerOffset) * .5;
    item.style.setProperty('--rotate', `${rotate}deg`);
    item.style.setProperty('--opacity', `${opacity}`);
    item.style.setProperty('--scale', `${scale}`);
    item.style.setProperty('--color', `${color}`);
    return centered;
}

function getScrollState(target: HTMLDivElement, items: HTMLDivElement[]): ArtistMenuScrollState{
    const {scrollTop, scrollHeight, offsetHeight} = target;
    const scrollPercentage = scrollTop / ((scrollHeight - offsetHeight) / 100);
    let centered = '';
    items.forEach((item) => {
        if(isCentered(item)) {
            centered = item.dataset.performer!;
        }
    });
    return {scrollTop, scrollHeight, offsetHeight, scrollPercentage, centered};
}

const useScrollArtistMenu = (ref: RefObject<HTMLDivElement>): ArtistMenuScrollState => {

    const [state, setState] = useState<ArtistMenuScrollState>({
        scrollTop: 0,
        scrollHeight: 0,
        scrollPercentage: 0,
        offsetHeight: 0,
        centered: '',
    });

    useEffect(() => {
        const items = Array.from(document.querySelectorAll('.menu-item'));
        const currentRef = ref.current;
        const handler = () => {
            if (currentRef) {
                setState(getScrollState(ref.current, items as HTMLDivElement[]));
            }
        };

        if (currentRef) {
            currentRef.addEventListener( 'scroll', handler, {
                capture: false,
                passive: true,
            });
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handler);
            }
        };
    }, [ref]);

    return state;
};

export default useScrollArtistMenu;
