import {RefObject, useEffect, useState} from 'react';
import {ScrollState} from '../model';

function getScrollState(target: HTMLDivElement): ScrollState{
    const {scrollTop, scrollHeight, offsetHeight} = target;
    const scrollPercentage = scrollTop / ((scrollHeight - offsetHeight) / 100);
    return {scrollTop, scrollHeight, offsetHeight, scrollPercentage};
}

const useScroll = (ref: RefObject<HTMLDivElement>): ScrollState => {

    const [state, setState] = useState<ScrollState>({
        scrollTop: 0,
        scrollHeight: 0,
        scrollPercentage: 0,
        offsetHeight: 0,
    });

    useEffect(() => {
        const currentRef = ref.current;
        const handler = () => {
            if (currentRef) {
                setState(getScrollState(currentRef));
            }
        };

        if (currentRef) {
            currentRef.addEventListener( 'scroll', handler, {
                capture: false,
                passive: true,
            });
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handler);
            }
        };
    }, [ref]);

    return state;
};

export default useScroll;
