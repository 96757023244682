import {FC, useEffect, useRef} from 'react';
import {DefaultPageContent} from '../../model';
import {useLoaderData} from 'react-router-dom';
import {fetchJson} from '../../api/api';
import './DefaultPage.css';

export async function loader({params}: any) {
    const {slug} = params;
    const content = await fetchJson(`/about/${slug}`) as DefaultPageContent;
    return {content}
}

const DefaultPage: FC = () => {
    const {content} = useLoaderData() as { content: DefaultPageContent };
    return <section className="default-page">
        {content && <>
            <div className="text">
                <h1>{content.title}</h1>
                <div dangerouslySetInnerHTML={{__html: content.text}}></div>
            </div>
        </>}
    </section>
}
export default DefaultPage;
