import React, {FC} from 'react';
import {MeshReflectorMaterial} from '@react-three/drei';


interface MirrorBoxProps {
    planeGeometryArgs?: [width: number, height: number, widthSegments?: number | undefined, heightSegments?: number | undefined];
    rotation?: [number, number, number];
    position?: [number, number, number];
}

interface MirrorPlaneProps extends MirrorBoxProps{
    planeGeometryArgs:  [width: number, height: number, widthSegments?: number | undefined, heightSegments?: number | undefined];
    rotation: [number, number, number];
    position: [number, number, number];
}

const MirrorPlane: FC<MirrorPlaneProps> = (props) => {
    return (
        <mesh rotation={props.rotation}
              position={props.position}
        >
            <planeGeometry args={props.planeGeometryArgs}/>
            <MeshReflectorMaterial
                roughness={0}
                metalness={0.9}
                color={0xffffff}
                blur={[0, 0]} // Blur ground reflections (width, heigt), 0 skips blur
                mixBlur={0} // How much blur mixes with surface roughness (default = 1)
                mixStrength={100} // Strength of the reflections
                mixContrast={1} // Contrast of the reflections
                resolution={768} // Off-buffer resolution, lower=faster, higher=better quality, slower
                mirror={1} // Mirror environment, 0 = texture colors, 1 = pick up env colors
                depthScale={0} // Scale the depth factor (0 = no depth, default = 0)
                minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
                maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
                depthToBlurRatioBias={0} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
                distortion={-0.5} // Amount of distortion based on the distortionMap texture
                reflectorOffset={0} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0)
            />
        </mesh>
    )
}

const Ground: FC<MirrorBoxProps> = (props) => {
    const [w, h] = props.planeGeometryArgs || [6, 6]
    return <MirrorPlane
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, w / 2 * -1, 0]}
        planeGeometryArgs={[h, w]}
    />
}

const Left:  FC<MirrorBoxProps> = (props) => {
    const [w] = props.planeGeometryArgs || [6, 6]
    const x = w / 2 * -1
    return <MirrorPlane
        rotation={[0, Math.PI / 2, 0]}
        position={[x, 0, 0]}
        planeGeometryArgs={props.planeGeometryArgs || [6, 6]}
    />
}

const Right:  FC<MirrorBoxProps> = (props) => {
    const [w, h] = props.planeGeometryArgs || [6, 6]
    const x = w / 2
    return <MirrorPlane
        rotation={[0, -Math.PI / 2, 0]}
        position={[x, 0, 0]}
        planeGeometryArgs={props.planeGeometryArgs || [6, 6]}
    />
}

const MirrorBox: FC<MirrorBoxProps> = (props) => {
    return <group position={props.position || [0, 0, 4]} rotation={props.rotation || [0, 0, 0]}>
        <Left planeGeometryArgs={props.planeGeometryArgs}></Left>
        <Ground planeGeometryArgs={props.planeGeometryArgs}></Ground>
        <Right planeGeometryArgs={props.planeGeometryArgs} ></Right>
    </group>
};

export default MirrorBox;
