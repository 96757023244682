import React, { FC } from 'react';
import {Euler, Vector3} from '@react-three/fiber';
import {Model} from './Model';

interface LogoProps {scale?: Vector3, rotation?: Euler, position?: Vector3}

const Logo: FC<LogoProps> = (props: LogoProps) => {
    const {rotation, position} = props;
        return <group position={position || 0} rotation={rotation || [0, 0, 0]} scale={props.scale || 1}>
        <Model/>
    </group>
};

export default Logo;
